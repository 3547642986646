import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostLimited from "../components/PostLimited"

const IndexPage = () => (
  <Layout>
    <SEO title="Accueil" />

    <PostLimited />

  </Layout>
)

export default IndexPage
