import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const PostLimited = () => {
  const data = useStaticQuery(
    graphql`
      query {
        blog: allContentfulPost(sort: { fields: date, order: DESC }) {
          edges {
            node {
              id
              name
              slug
              image {
                file {
                  url
                }
              }
              date(formatString: "DD MMM YYYY", locale: "fr")
            }
          }
        }
      }
    `
  )
  return (
    <>
     
      <div className="blog-limited">

        <div className="limited-grid">
          {data.blog.edges.slice(0, 100).map((edge, i) => {
            return (
              <div className="card" key={i}>
                <div>
                  <div
                    className="post-limited-image"
                    style={{
                      backgroundImage: "url(" + edge.node.image.file.url + ")",
                      filter: "saturate(1.3)"

                    }}
                  ></div>
                  <div className="padding-30">
                    <Link to={`/blog/${edge.node.slug}/`}>
                      <h1 className="tow-line medium small-title">{edge.node.name}</h1>
                    </Link>
                    <div>{edge.node.excerpt}</div>
                    <span className="limited-date">
                      {edge.node.date}
                    </span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default PostLimited
